import { FC, useEffect, useRef, useState } from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { IosShareOutlined as ExportIcon } from "@mui/icons-material";
import moment from "moment";
import { DateRange } from "react-day-picker";

import "./style.css";
import { CRUDTemplate } from "src/components";
import DateRangePickerMenu from "src/components/molecules/dateRange/dateRangePickerMenu";
import { useClients } from "src/hooks/clients";
import { ClientDashboardProps } from "src/types/hooks/financesApi/clients";
import { IEntityPaginated } from "src/types";

//Variable to initialize range state
const initialRange: DateRange = {
  from: moment().startOf("month").toDate(),
  to: new Date(),
};

const blankEntitiesPaginated: IEntityPaginated<ClientDashboardProps> = {
  items: [],
  pages: 0,
  page: 0,
  total: 0,
};

/**
 * This component returns a dashboard with information of the clients
 * It uses mui styles
 * @component
 */
const ClientDashboard: FC = () => {
  //Dashboard hooks to fetch the clients data
  const { getDashboardData } = useClients();

  //State loading
  const [loading, setLoading] = useState<boolean>(true);
  //State for the clients
  const [clients, setClients] = useState<
    IEntityPaginated<ClientDashboardProps>
  >(blankEntitiesPaginated);
  //State for select component
  const [periodo, setPeriodo] = useState<string>("2");
  //State
  const [range, setRange] = useState<DateRange | undefined>(initialRange);
  //State for initialDate
  const [initialDate, setInitialDate] = useState<Date>(
    moment().startOf("month").toDate()
  );
  //State for finalDate
  const [finalDate, setFinalDate] = useState<Date>(
    moment().endOf("month").toDate()
  );
  //State for anchor datepicker component to the combobox
  const selectRangeRef = useRef(null);
  //State
  const [rangeAnchor, setRangeAnchor] = useState<null | HTMLElement>(null);
  // State for current data grid page
  const [dataGridPage, setDataGridPage] = useState<number>(1);
  //State for rows per page
  const [perPage, setPerPage] = useState<number>(5);
  //State for input search
  const [search, setSearch] = useState<string>("");
  //State for timeout
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  /**
   * Reflects when component inits
   * @effect
   */
  useEffect(() => {
    fetchData(initialDate, finalDate, dataGridPage, perPage, search);
  }, []);

  /**
   * Reflects when detect combobox change
   * @effect
   */
  useEffect(() => {
    fetchData(initialDate, finalDate, dataGridPage, perPage, search);
  }, [initialDate, finalDate]);

  /**
   * Reflects when detect dateRange change
   * @effect
   */
  useEffect(() => {
    if (range && range.from && range.to) {
      fetchData(range.from, range.to, dataGridPage, perPage, search);
    }
  }, [range]);

  /**
   * Function to fetch all necessary data of the page
   * @function
   */
  const fetchData = async (
    initialDate: Date,
    finalDate: Date,
    page: number,
    perPage: number,
    search: string
  ) => {
    setLoading(true);
    try {
      const auxClients: IEntityPaginated<ClientDashboardProps> =
        await getDashboardData(initialDate, finalDate, page, perPage, search);
      setClients({ ...auxClients });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  /**
   * Function to handle change in select component
   * @function
   */
  const handleChange = (event: SelectChangeEvent) => {
    setPeriodo(event.target.value as string);
  };

  /**
   * Function to handle click on the selectbox and calculate the date interval
   * @function
   */
  const handleDate = (
    days: number,
    currentMonth: boolean = false,
    currentYear: boolean = false
  ) => {
    let startDate, endDate;

    if (currentMonth) {
      startDate = moment().startOf("month").toDate();
      endDate = moment().endOf("month").toDate();
    } else if (currentYear) {
      startDate = moment().startOf("year").toDate();
      endDate = moment().endOf("year").toDate();
    } else {
      endDate = moment().toDate();
      startDate = moment().subtract(days, "days").toDate();
    }
    setInitialDate(startDate);
    setFinalDate(endDate);
  };
  /**
   * Function to handle click on the 'Personalizado' option of combobox
   */
  const open = Boolean(rangeAnchor);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setRangeAnchor(selectRangeRef.current);
  };
  /**
   * Function to handle when closing modal
   */
  const handleClose = () => {
    setRangeAnchor(null);
  };
  /**
   * Function to handle input search
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        if (periodo == "5") {
          if (range && range.from && range.to) {
            fetchData(range.from, range.to, dataGridPage, perPage, value);
          }
        } else fetchData(initialDate, finalDate, dataGridPage, perPage, value);
      }, 1000)
    );
  };

  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <CRUDTemplate
      dataGridLoading={loading}
      title="Clientes IDEXA"
      headerTitle=""
      headerChildren={
        <Grid container columnSpacing={1}>
          <Grid item sm={12} md={6} lg={8}>
            <TextField
              variant="outlined"
              label="Empresa ou email"
              size="small"
              onChange={handleInputChange}
              value={search}
              placeholder="Empresa X"
              fullWidth
            />
          </Grid>
          <Grid item sm={6} md={3} lg={2}>
            <Box width="100%">
              <FormControl>
                <InputLabel>Período</InputLabel>
                <Select
                  value={periodo}
                  label="Período"
                  defaultValue={"30"}
                  onChange={handleChange}
                  ref={selectRangeRef}
                  sx={{ minWidth: "150px" }}
                >
                  <MenuItem onClick={() => handleDate(7)} value={0}>
                    Últimos 7 dias
                  </MenuItem>
                  <MenuItem onClick={() => handleDate(15)} value={1}>
                    Últimos 15 dias
                  </MenuItem>
                  <MenuItem onClick={() => handleDate(30, true)} value={2}>
                    Mês Atual
                  </MenuItem>
                  <MenuItem onClick={() => handleDate(180)} value={3}>
                    Últimos 6 meses
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDate(365, false, true)}
                    value={4}
                  >
                    Este Ano
                  </MenuItem>
                  <MenuItem
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    value={5}
                  >
                    Personalizado
                  </MenuItem>
                </Select>
              </FormControl>
              <DateRangePickerMenu
                anchorEl={rangeAnchor}
                open={open}
                onClose={handleClose}
                selected={range}
                onSelect={setRange}
              />
            </Box>
          </Grid>
          <Grid item sm={6} md={3} lg={2}>
            <Button
              disabled
              variant="contained"
              startIcon={<ExportIcon color="inherit" />}
            >
              <Typography
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="20px"
                letterSpacing="0.1px"
                color="inherit"
                textTransform="initial"
              >
                Exportar dados
              </Typography>
            </Button>
          </Grid>
        </Grid>
      }
      dataGridColumns={clientDashboardDataGridColumns}
      dataGridRows={clients.items}
      dataGridPage={dataGridPage}
      paginationMode="server"
      paginationModel={{
        page: dataGridPage - 1,
        pageSize: perPage,
      }}
      onPaginationModelChange={(model, details) => {
        setDataGridPage(model.page + 1);
        setPerPage(model.pageSize);

        fetchData(
          initialDate,
          finalDate,
          model.page + 1,
          model.pageSize,
          search
        );
      }}
      rowCount={clients.total}
      labelRowsPerPage="Clientes por página"
    >
      <div />
    </CRUDTemplate>
  );
};

export default ClientDashboard;

export const clientDashboardDataGridColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Empresa",
    minWidth: 75,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Criação",
    minWidth: 75,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.value)
        return moment(params.value).format("DD/MM/YYYY - HH:mm");
      return "";
    },
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 75,
    flex: 1,
  },
  {
    field: "subscriptionPlan",
    headerName: "Plano",
    minWidth: 75,
    flex: 1,
    valueGetter: (params) => {
      if (params.row.subscriptionPlan) return params.row.subscriptionPlan.name;
      return "Não encontrado";
    },
  },
  {
    field: "subscriptionUsage",
    headerName: "Uploads",
    minWidth: 75,
    flex: 1,
    valueGetter: (params) => {
      if (params.row.subscriptionUsage)
        return params.row.subscriptionUsage.totalRequests;
      return "0";
    },
  },
  {
    field: "subscriptionUsage.finishedCount",
    headerName: "Páginas",
    minWidth: 75,
    flex: 1,
    valueGetter: (params) => {
      if (params.row.subscriptionUsage.finishedCount)
        return params.row.subscriptionUsage.finishedCount;
      return "0";
    },
  },
  {
    field: "lastUpload",
    headerName: "Última Requisição",
    minWidth: 75,
    flex: 1,
    valueGetter: (params) => {
      if (
        params.row.subscriptionUsage &&
        params.row.subscriptionUsage.lastUpload !== null
      )
        return moment(params.row.subscriptionUsage.lastUpload).format(
          "DD/MM/YYYY - HH:mm"
        );
      return "";
    },
  },
];
