import { AxiosResponse } from "axios";

import SubscriptionService from "./subscriptions";
import SubscriptionPlanService from "./subscriptionPlans";
import api, { API_FINANCES_URL } from "src/services";
import CRUDBaseService from "src/services/base";
import { ClientHasUsersProps, ClientProps, IEntityPaginated, SubscriptionPlanProps, SubscriptionUsageRecordProps, UserProps } from "src/types";
import { ClientDashboardProps } from "src/types/hooks/financesApi/clients";
import { parseStringToDate } from "src/utils/dates";

export default class ClientService extends CRUDBaseService<ClientProps> {
  constructor() {
    super(api, `${API_FINANCES_URL}/clients`);
  }
  
  async getByUserId(userId: string): Promise<ClientProps> {
    try {
      const response: AxiosResponse<ClientProps> = await this.api.get(`${this.baseURL}/users/${userId}`);
      return this.convertApiResponseToIEntity(response.data);
    } catch (error) {
      this.handleError(error);
    }
  }
  
  async getUsers(id: string): Promise<UserProps[]> {
    try {
      const response: AxiosResponse<UserProps[]> = await this.api.get(`${this.baseURL}/${id}/users`);
      const users: UserProps[] = [];
      for (let i = 0; i < response.data.length; i++) {
        users.push(this.convertApiResponseToUserProps(response.data[i]));
      }
      return users;
    } catch (error) {
      this.handleError(error);
    }
  }
  
  async createHasUsers(payload: ClientHasUsersProps): Promise<ClientHasUsersProps> {
    try {
      const response: AxiosResponse<UserProps[]> = await this.api.post(`${this.baseURL}/users`, payload);
      const hasUser: ClientHasUsersProps = this.convertApiResponseToClientHasUserProps(response.data);
      return hasUser;
    } catch (error) {
      this.handleError(error);
    }
  }
  
  /**
  * Function to access the backend and fetch clients data
  * @function
  */
  async getDashboardData(initialDate: string, finalDate: string, page: number, perPage: number, search: string): Promise<IEntityPaginated<ClientDashboardProps>> {
    let url = `${this.baseURL}/dashboard?initial_date=${initialDate}&final_date=${finalDate}&page=${page}&per_page=${perPage}`;
    if (search) url+=`&search=${search}`;
    
    const response = await this.api.get(url);
    if(response.data && response?.request?.status === 200){
      const clients: ClientDashboardProps[] = [];

      const data = response.data;
      for (let i = 0; i < data.items.length; i++){
        const item = data.items[i];
        const client: ClientDashboardProps = this.convertApiResponseToClientDashboardProps(item);

        clients.push(client)
      }
      return {
        items: clients,
        page: data.page,
        pages: data.pages,
        total: data.total
      }
    }
    throw new Error("Não foi possível buscar os clientes.")
  }
  
  override convertApiResponseToIEntity(_data: any): ClientProps {
    return {
      id: _data.id,
      name: _data.name,
      email: _data.email,
      country: _data.country,
      postalCode: _data.postalCode,
      street: _data.street,
      number: _data.number,
      additionalInformation: _data.additionalInformation,
      district: _data.district,
      state: _data.state,
      cityCode: _data.cityCode,
      cityName: _data.cityName,
      canChangeSubscription: _data.canChangeSubscription ?? false,
      
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
  
  convertApiResponseToClientHasUserProps(_data: any): ClientHasUsersProps {
    return {
      clientId: _data.clientId,
      userId: _data.userId,
    };
  }
  
  convertApiResponseToUserProps(_data: any): UserProps {
    return {
      id: _data.id,
      username: _data.username,
      email: _data.email,
      enabled: _data.enabled,
      emailVerified: _data.emailVerified,
      firstName: _data.firstName,
      lastName: _data.lastName,
      isActive: _data.isActive,
    };
  }
  
  /**
  * Function to convert the data fetched from backend to ClientDashboardProps interface
  * @function
  */
  convertApiResponseToClientDashboardProps(_data: any): ClientDashboardProps{
    
    const users: UserProps[] = [];
    try {
      for (let i = 0; i < _data.users.length; i++) {
        users.push(this.convertApiResponseToUserProps(_data.users[i]));
      }
    } catch (error){
      console.log(error)
    }
    
    const subscriptionService = new SubscriptionService();
    const subscriptionUsage: SubscriptionUsageRecordProps = subscriptionService.convertApiResponseToSubscriptionUsageRecordProps(_data.subscription_usage);
    
    const subscriptionPlanService = new SubscriptionPlanService();
    const subscriptionPlan: SubscriptionPlanProps = subscriptionPlanService.convertApiResponseToIEntity(_data.subscription_plan);
    
    return {
      id: _data.id,
      name: _data.name,
      email: _data.email,
      users: users,
      subscriptionPlan: subscriptionPlan,
      subscriptionUsage: subscriptionUsage,
      createdAt: _data.createdAt
    };
  }
}
