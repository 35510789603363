import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import {
    CalendarTodayOutlined as CreatedAtIcon,
    ChevronLeft as GoBackIcon,
    IosShareOutlined as ExportIcon,
    PersonOutlined as UserIcon,
    SpeedOutlined as DurationIcon
} from '@mui/icons-material';
import moment from 'moment';

import { DefaultTemplate, SplitButton, theme } from 'src/components';
import DocumentsResultDataShow from 'src/components/molecules/v2/documents/results/dataShow';
import DocumentsResultFilePreview from 'src/components/molecules/v2/documents/results/filePreview';
import { useDocuments } from 'src/hooks/documents';
import { useResponsive } from 'src/hooks/responsive';
import { IDocument } from 'src/types';
import { downloadCSVFromJson } from 'src/utils/converters';
import { isUUID } from 'src/utils/stringFormatters';


/**
 * This component returns the Documents results page
 * It uses mui styles
 * @component
 */
const DocumentsResults: FC = () => {
  // Component params in path
  const { id } = useParams();

  const documents = useDocuments();
  // Navigate hooks to change route based on path
  const navigate = useNavigate();
  // Responsive hooks for layout changes
  const responsive = useResponsive();

  // State for current document
  const [currentDocument, setCurrentDocument] = useState<IDocument | undefined>(undefined);
  // State for current document file
  const [fileBase64, setFileBase64] = useState<string | undefined>(undefined);
  // State for menu button anchor
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  // If menu is open or not
  const menuOpen = Boolean(menuAnchor);

  /**
   * Effect to fetch initial data
   * @effect
   */
  useEffect(() => {
    fetchData();
  }, [id]);

  /**
   * Function to fetch initial data
   * @function
   */
  const fetchData = async () => {
    if (!id) return;

    let auxDocument: IDocument | undefined = undefined;
    // This component can receives an id UUID or id Index of the document
    if (isUUID(id)) auxDocument = await documents.fetchEntity(id);
    else {
      try {
        const index: number = parseInt(id);
        auxDocument = await documents.fetchDocumentByIndex(index);
      } catch (err) {
        return;
      }
    }
    setCurrentDocument(auxDocument);
  };

  /**
   * Function that handle menu button click
   * @function
   */
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  /**
   * Function that handle menu button close
   * @function
   */
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  /**
   * Function that downloads the document data
   * @function
   */
  const download = async () => {
    if (!currentDocument) return null;

    const jsonData = JSON.stringify(currentDocument.data);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement("a");
    link.href = url;
    link.setAttribute("download", `documento_${currentDocument.index}.json`);
    window.document.body.appendChild(link);
    link.click();

    handleMenuClose();
  };

  const handleCsvDownload = () => {
    if (!currentDocument?.csvUrl) return;

    const link = document.createElement("a");
    link.href = currentDocument?.csvUrl;
    link.download = `${currentDocument.index}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DefaultTemplate
      title={!currentDocument ? "Carregando" : `ID: ${currentDocument.index}`}
      headerStart={
        <Box display="flex" paddingRight="16px">
          <IconButton color="primary" onClick={() => navigate("/documents")}>
            <GoBackIcon color="inherit" />
          </IconButton>
        </Box>
      }
      headerEnd={
        <Box
          display="flex"
          flexDirection={responsive.smUp ? "row" : "column"}
          flex={responsive.smUp ? 1 : 0}
          flexGrow={responsive.smUp ? 1 : 0}
          alignItems={responsive.smUp ? "center" : "flex-end"}
          justifyContent="flex-end"
        >
          {currentDocument?.userName && (
            <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="center" paddingLeft="16px">
              <UserIcon sx={{ color: theme.palette.text.secondary }} />
              <Typography
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.5px"
                color="text.secondary"
                paddingLeft="4px"
              >
                {currentDocument?.userName}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="center" paddingLeft="16px">
            <CreatedAtIcon sx={{ color: theme.palette.text.secondary }} />
            <Typography
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="24px"
              letterSpacing="0.5px"
              color="text.secondary"
              paddingLeft="4px"
            >
              {moment(currentDocument?.createdAt).format("DD/MM/YYYY - HH:mm")}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="center" paddingLeft="16px">
            <DurationIcon sx={{ color: theme.palette.text.secondary }} />
            <Typography
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="24px"
              letterSpacing="0.5px"
              color="text.secondary"
              paddingLeft="4px"
            >
              {currentDocument?.finishedAt
                ? moment(currentDocument?.finishedAt).diff(moment(currentDocument?.inProgressAt || currentDocument?.createdAt), "seconds")
                : "--"}{" "}
              segundos
            </Typography>
          </Box>
        </Box>
      }
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          flexGrow: 1,
          alignItems: "center",
          paddingX: "32px",
          paddingY: "24px",
          borderRadius: "12px",
        }}
      >
        <CardContent sx={{ width: "100%", maxWidth: "100%" }}>
          <Box display="flex" flexDirection="row" flex={1} alignItems="center" justifyContent="space-between">
            <Typography fontSize="22px" fontStyle="normal" fontWeight="400" lineHeight="28px" color={theme.palette.primary.main}>
              Modelo: {currentDocument?.model?.description}
            </Typography>
            <SplitButton
              text="Exportar dados"
              icon={<ExportIcon color="inherit" />}
              options={currentDocument?.csvUrl ? [{ text: "Baixar planilha", onClick: () => handleCsvDownload(), options: [] }] : []}
              onClick={() => download()}
            ></SplitButton>
          </Box>

          <Grid container rowSpacing="24px" columnSpacing="24px" paddingTop="24px">
            <Grid item xs={12} lg={5}>
              {currentDocument && currentDocument.fileType && (
                <DocumentsResultFilePreview fileUrl={currentDocument.file} extension={currentDocument.fileType} />
              )}
            </Grid>
            <Grid item xs={12} lg={7}>
              {currentDocument && currentDocument.id && currentDocument.viewData && (
                <DocumentsResultDataShow id={currentDocument.id} data={currentDocument.viewData} refresh={() => fetchData()} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Menu id="crud-document-status-cell-menu-button" anchorEl={menuAnchor} open={menuOpen} onClose={handleMenuClose}>
        {/* <MenuItem onClick={() => {
                    if (document)
                        downloadCSVFromJson(`documento_${document.index}.csv`, [document.data]);
                    handleMenuClose();
                }}>
                    <Box display="flex">
                        <Typography fontSize="16px" fontStyle="normal" fontWeight="400"
                            lineHeight="24px" letterSpacing="0.5px"
                            sx={{ paddingX: "12px" }}>
                            Planilha
                        </Typography>
                    </Box>
                </MenuItem> */}

        <MenuItem
          onClick={() => {
            if (currentDocument) download();
            handleMenuClose();
          }}
        >
          <Box display="flex">
            <Typography fontSize="16px" fontStyle="normal" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" sx={{ paddingX: "12px" }}>
              Json
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </DefaultTemplate>
  );
};

export default DocumentsResults;
