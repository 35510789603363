import { AxiosResponse } from "axios";

import api, { API_DOCUMENTS_URL } from ".";
import { EntityServices } from "./entities";
import {
  IDocument,
  IDocumentType,
  IDocumentPayload,
  IDocumentStatusVision,
  IEntityPaginated,
  IDocumentCustomModel,
  IDocumentModel,
  ApiKeyProps,
} from "../types";
import { parseStringToDate } from "../utils/dates";
import { DocumentCustomModelServices } from "./documentsCustomsModels";
import { DocumentModelServices } from "./documentModels";
import { DocumentResponseProps } from "src/types/hooks/documents";

export class DocumentServices extends EntityServices<IDocument> {
  constructor() {
    super("Documento", `${API_DOCUMENTS_URL}/documents`);
  }

  async getDocuments(_queryString: string): Promise<IEntityPaginated<IDocument>> {
    const response = await api.get(`${this.baseUrl}${_queryString}`);
    if (response.data && response?.request?.status === 200) {
      const documents: IDocument[] = [];

      const data = response.data;
      for (let i = 0; i < data.items.length; i++) {
        const item = data.items[i];
        const document: IDocument = this.convertApiResponseToIEntity(item);

        documents.push(document);
      }
      return {
        items: documents,
        page: data.page,
        pages: data.pages,
        total: data.total,
      };
    }
    throw new Error("Não foi possível buscar os documentos");
  }

  async getDocumentByIndex(_index: number, _query: string = ""): Promise<IDocument> {
    let response: AxiosResponse | any = await api.get(`${this.baseUrl}/index/${_index}${_query}`);
    if ((response as any).response != undefined) response = (response as any).response;
    if (response.data && response.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    } else if (response.status == 403) {
      if (response.data.error) {
        if (response.data.error == "The user does not have access rights to document")
          throw new Error("Você não possui permissões para acessar o documento.");
      }
    }
    throw new Error(`Não foi possível buscar o(a) ${this.entityForLog}`);
  }

  async getDocumentsByClientId(_clientId: string, _queryString: string): Promise<IEntityPaginated<IDocument>> {
    const response = await api.get(`${this.baseUrl}/clients/${_clientId}${_queryString}`);

    if (response.data && response?.request?.status === 200) {
      const documents: IDocument[] = [];

      const data = response.data;
      for (let i = 0; i < data.items.length; i++) {
        const item = data.items[i];
        const document: IDocument = this.convertApiResponseToIEntity(item);

        documents.push(document);
      }
      return {
        items: documents,
        page: data.page,
        pages: data.pages,
        total: data.total,
      };
    }
    throw new Error("Não foi possível buscar os documentos");
  }

  async uploadDocument(_payload: IDocumentPayload, _apiKey: string): Promise<IDocument> {
    const response: AxiosResponse = await api.post(`${this.baseUrl}/upload`, _payload);
    if (response?.data && response?.request?.status == 200) {
      let document: IDocument = this.convertApiResponseToIEntity(response.data);
      if (!document.id) throw new Error("Erro ao subir o documento.");
      return document;
    } else if (response?.data && response?.request?.status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    } else if (response?.request?.status == 403) {
      const monthlyRequests: number = (response as any)?.response?.data?.data?.monthlyRequests ?? 0;
      if (monthlyRequests > 0) throw (response as any)?.response?.data;

      if ((response as any)?.response?.data?.error == "Your 7 days trial subscription has no more rights to use Pix Docs API. Please contact us.")
        throw new Error(
          "Seu período de avaliação de 7 dias chegou ao fim. Para continuar aproveitando nossos serviços , explore nossos planos e escolha a opção que melhor atenda às suas necessidades"
        );
      throw new Error("O limite de requisições de sua assinatura foi alcançado. Favor entrar em contato com a equipe Idexa.");
    }

    throw new Error("Não foi possível criar um documento");
  }

  async resend(_id: string): Promise<IDocument> {
    const response: AxiosResponse = await api.put(`${this.baseUrl}/${_id}/resend`);
    if (response?.data && response?.request?.status == 201) {
      let document: IDocument = this.convertApiResponseToIEntity(response.data);
      if (!document.id) throw new Error("Erro ao reenviar o documento.");
      return document;
    }
    throw new Error("Não foi possível reenviar o documento");
  }

  async viewDataEdit(_id: string, viewData: any): Promise<IDocument> {
    const response: AxiosResponse = await api.put(`${this.baseUrl}/${_id}/view_data_edit`, {
      viewData: viewData,
    });
    if (response?.data && response?.request?.status == 201) {
      let document: IDocument = this.convertApiResponseToIEntity(response.data);
      if (!document.id) throw new Error("Erro ao editar o resultado do documento.");
      return document;
    }
    throw new Error("Não foi possível editar o resultado do documento");
  }

  async getDocumentsTypes(): Promise<IDocumentType[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/list_types`);

    if (response.data && response?.request?.status === 200) {
      const documentsTypes: IDocumentType[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const documentType: IDocumentType = this.convertApiResponseToIDocumentType(data);

        documentsTypes.push(documentType);
      }
      return documentsTypes;
    }
    return [];
  }

  async getAvailablesDocumentsTypes(): Promise<IDocumentType[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/available_types`);

    if (response.data && response?.request?.status === 200) {
      const documentsTypes: IDocumentType[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const documentType: IDocumentType = this.convertApiResponseToIDocumentType(data);

        documentsTypes.push(documentType);
      }
      return documentsTypes;
    }
    return [];
  }

  async getDocumentsSubtypes(_type: number): Promise<IDocumentType[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/list_types/subtypes/${_type}`);

    if (response.data && response?.request?.status === 200) {
      const documentsTypes: IDocumentType[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const documentType: IDocumentType = this.convertApiResponseToIDocumentType(data);

        documentsTypes.push(documentType);
      }
      return documentsTypes;
    }
    throw new Error("Não foi possível buscar os subtipos dos documentos");
  }

  async downloadResponses(_ids: string[]): Promise<any> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/download_responses?ids=${_ids.join(",")}`, {
      responseType: "blob",
    });
    if (response.data && response?.request?.status === 200) {
      return response.data;
    }
    throw new Error("Não foi possível buscar os subtipos dos documentos");
  }

  async countAllTypesDocumentsByMonth(clientId: string, month: number, year: number): Promise<number> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/count_all_types_by_month_number/${clientId}?month=${month}&year=${year}`);
    if (response.data && response?.request?.status === 200) {
      return response.data.count;
    }
    throw new Error("Não foi possível buscar a contagem dos documentos");
  }

  async countAllTypesAllMonths(clientId: string, year: number): Promise<any> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/count_all_types_all_months/${clientId}?year=${year}`);
    if (response.data && response?.request?.status === 200) {
      return response.data;
    }
    throw new Error("Não foi possível buscar a contagem dos documentos");
  }

  async countTypeDocumentsByMonth(clientId: string, type: number, month: number, year: number): Promise<number> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/count_type_by_month_number/${clientId}/${type}?month=${month}&year=${year}`);
    if (response.data && response?.request?.status === 200) {
      return response.data.count;
    }
    throw new Error("Não foi possível buscar a contagem dos documentos");
  }

  async countTypeDocumentsAllMonths(clientId: string, type: number, year: number): Promise<any> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/count_type_all_months/${clientId}/${type}?year=${year}`);
    if (response.data && response?.request?.status === 200) {
      return response.data;
    }
    throw new Error("Não foi possível buscar a contagem dos documentos");
  }

  convertApiResponseToDocumentResponseProps(_data: any): DocumentResponseProps {
    return {
      documentId: _data.document_id,
      data: _data.data,
      viewData: _data.view_data,
      processTime: _data.process_time,
      analyzedPages: _data.analyzed_pages,
      inProgressAt: _data.in_progress_at ? parseStringToDate(_data.in_progress_at, "YYYY-MM-DD HH:mm:ss") : undefined,
      finishedAt: _data.finished_at ? parseStringToDate(_data.finished_at, "YYYY-MM-DD HH:mm:ss") : undefined,
      errorAt: _data.error_at ? parseStringToDate(_data.error_at, "YYYY-MM-DD HH:mm:ss") : undefined,
      csvUrl: _data.csv_url,
    };
  }

  override convertApiResponseToIEntity(_data: any): IDocument {
    let statusDesc: string = "Erro";
    if (_data.statusDesc == "RECEIVED") statusDesc = "Processando";
    else if (_data.statusDesc == "FINISHED") statusDesc = "Finalizado";
    else if (_data.statusDesc == "ERROR") statusDesc = "Erro";
    else if (_data.statusDesc == "IN_PROGRESS") statusDesc = "Processando";
    else if (_data.statusDesc == "IN_CONVERT") statusDesc = "Processando";
    else if (_data.statusDesc == "IN_CROP") statusDesc = "Processando";
    else if (_data.statusDesc == "IN_OCR") statusDesc = "Processando";
    else if (_data.statusDesc == "IN_ANALYSIS") statusDesc = "Processando";

    let apiKey: ApiKeyProps | undefined = undefined;
    if (_data.apiKey) {
      apiKey = {
        clientId: _data.apiKey.clientId,
        name: _data.apiKey.name,
      };
    }

    let user: any = _data.user;
    let userName: string | undefined = undefined;
    if (user) {
      userName = user.firstName;
      if (user.lastName) userName += ` ${user.lastName}`;
    }

    let statusVision: IDocumentStatusVision | undefined = undefined;
    if (_data.statusVision) {
      statusVision = {
        id: _data.statusVision.id,
        documentId: _data.statusVision.documentId,
        status: _data.statusVision.status,
        statusDesc: _data.statusVision.statusDesc,
        statusMessage: _data.statusVision.statusMessage,
        attempt: _data.statusVision.attempt,
        errorMessage: _data.statusVision.errorMessage,
        createdAt: _data.statusVision.createdAt,
        updatedAt: _data.statusVision.updatedAt,
      };
    }

    let model: IDocumentModel | undefined = undefined;
    if (_data.model) {
      const documentModelService: DocumentModelServices = new DocumentModelServices();
      model = documentModelService.convertApiResponseToIEntity(_data.model);
    }

    let documentCustomModel: IDocumentCustomModel | undefined = undefined;
    if (_data.documentCustomModel) {
      const documentCustomModelService: DocumentCustomModelServices = new DocumentCustomModelServices();
      documentCustomModel = documentCustomModelService.convertApiResponseToIEntity(_data.documentCustomModel);
    }

    if (_data.response) {
    }

    return {
      index: _data.index,
      id: _data.id,
      userId: _data.userId,
      apiKey,
      type: _data.type,
      modelName: _data.modelName,
      data: _data.response && _data.response.data,
      viewData: _data.response.viewData,
      processTime: _data.response.processTime,
      csvUrl: _data.response.csvUrl,
      status: _data.status,
      statusDesc: statusDesc,
      subscripted: _data.subscripted,
      file: _data.file,
      fileType: _data.fileType,
      fields: _data.fields,
      pages: _data.pages,
      analyzedPages: _data.analyzedPages,
      isDeleted: _data.isDeleted,
      inProgressAt: _data.response.inProgressAt ? parseStringToDate(_data.response.inProgressAt, "YYYY-MM-DD HH:mm:ss") : undefined,
      finishedAt: _data.response.finishedAt ? parseStringToDate(_data.response.finishedAt, "YYYY-MM-DD HH:mm:ss") : undefined,
      source: _data.source,
      sourceDesc: _data.sourceDesc,
      userName,
      statusVision,
      documentCustomModelId: _data.documentCustomModelId,
      documentCustomModel,
      model,
      errorAt: _data.response.errorAt ? parseStringToDate(_data.response.errorAt, "YYYY-MM-DD HH:mm:ss") : undefined,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }

  convertApiResponseToIDocumentType(_data: any): IDocumentType {
    return {
      value: _data.value,
      name: _data.name,
      description: _data.description,
    };
  }
}
