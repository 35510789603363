import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import { ClientServices } from "../services/clients";
import {
  ClientHasUsersProps,
  IClient,
  IClientContext,
  IEntityPaginated,
  IHookProvider,
  IUser,
  UserProps,
} from "../types";
import ClientService from "src/services/financesApi/clients";
import { ClientDashboardProps } from "src/types/hooks/financesApi/clients";

const ClientsContext = createContext<IClientContext>({} as IClientContext);

export const ClientsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const clientServices = new ClientServices();
  const [entities, setEntities] = useState<IClient[]>([]);
  const [filteredEntities, setFilteredEntities] = useState<IClient[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    filterEntities(search);
  }, [search, entities]);

  const filterEntities = (_search: string) => {
    let auxEntities = [...entities];
    if (_search !== "")
      auxEntities = auxEntities.filter(
        (item) =>
          item.name.toLowerCase().indexOf(_search.toLowerCase()) >= 0 ||
          (item.email &&
            item.email.toLowerCase().indexOf(_search.toLowerCase()) >= 0)
      );
    setFilteredEntities(auxEntities);
  };

  const createNewEntity = async (_client: IClient) => {
    try {
      const client = await clientServices.createEntity(_client);
      fetchEntities();
      return client;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _client: IClient) => {
    try {
      const client = await clientServices.updateEntity(_id, _client);
      fetchEntities();
      return client;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const client = await clientServices.destroyEntity(_id);
      fetchEntities();
      return client;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const clients: IClient[] = await clientServices.getEntities();
      setEntities([...clients]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const client: IClient = await clientServices.getEntity(_id);
    return client;
  };

  const fetchClientUsersId = async (_id: string) => {
    const usersCount: string[] = await clientServices.getClientUsersId(_id);
    return usersCount;
  };

  const fetchClientUsersCount = async (_id: string) => {
    const usersCount: number = await clientServices.getClientUsersCount(_id);
    return usersCount;
  };

  const fetchClientByUserId = async (_userId: string) => {
    const client: IClient | undefined = await clientServices.getClientByUserId(
      _userId
    );
    if (client) return client;
    return undefined;
  };

  const getUsers = async (id: string) => {
    try {
      const users: UserProps[] = await new ClientService().getUsers(id);
      return users;
    } catch (err) {
      throw err;
    }
  };

  const createClientHasUser = async (_clientId: string, _userId: string) => {
    try {
      const payload: ClientHasUsersProps = {
        clientId: _clientId,
        userId: _userId,
      };
      const hasUser: ClientHasUsersProps =
        await new ClientService().createHasUsers(payload);
      return hasUser;
    } catch (_err) {
      throw _err;
    }
  };

  const getDashboardData = async (
    initialDate: Date,
    finalDate: Date,
    page: number,
    perPage: number,
    search: string
  ) => {
    try {
      const clients: IEntityPaginated<ClientDashboardProps> =
        await new ClientService().getDashboardData(
          moment(initialDate).format("YYYY-MM-DD"),
          moment(finalDate).format("YYYY-MM-DD"),
          page,
          perPage,
          search
        );
      return clients;
    } catch (err) {
      throw err;
    }
  };

  return (
    <ClientsContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,

        fetchClientUsersCount,
        fetchClientUsersId,
        fetchClientByUserId,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,

        search,
        setSearch,
        filteredEntities,

        getUsers,
        createClientHasUser,
        getDashboardData,
      }}
    >
      {_params.children}
    </ClientsContext.Provider>
  );
};

export function useClients() {
  const context = useContext(ClientsContext);

  if (!context) {
    throw new Error("useClients must be used within an ClientsProvider");
  }

  return context;
}
